import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { getToken } from '../utils/getToken';
import Chart from 'chart.js/auto';

const FinancialData = () => {
    const [monthlyData, setMonthlyData] = useState({});
    const [chartData, setChartData] = useState(null);
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchFinancialData = async () => {
            const token = getToken();
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const requestOptions = {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                redirect: "follow"
            };

            try {
                const response = await fetch("https://api.quickcare.ng/admin/financials/monthly-subscriptions?country=nigeria", requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log('Server response:', result); // Log the server response here
                setMonthlyData(result.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchFinancialData();
    }, []);

    useEffect(() => {
        const labels = Object.keys(monthlyData);
        const data = Object.values(monthlyData);

        if (labels.length > 0 && data.length > 0) {
            const newChartData = {
                labels: labels,
                datasets: [{
                    label: 'Monthly Revenue',
                    data: data,
                    backgroundColor: '#308042',
                    borderColor: '#1c6d40',
                    borderWidth: 1
                }],
            };

            setChartData(newChartData);
        }
    }, [monthlyData]);

    useEffect(() => {
        if (chartRef.current && chartData) {
            const chartInstance = new Chart(chartRef.current, {
                type: 'bar',
                data: chartData,
                options: {
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    return `${tooltipItem.label}: ${tooltipItem.raw}`;
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                display: true,
                                borderDash: [10]
                            },
                            ticks: {
                                autoSkip: false, // Ensure all labels are displayed
                            }
                        },
                        y: {
                            min: 0,
                            max: 2000,
                            ticks: {
                                stepSize: 100,
                                callback: function(value) {
                                    return value.toLocaleString(); // Format numbers with commas
                                }
                            },
                            grid: {
                                borderDash: [15]
                            }
                        }
                    }
                }
            });

            return () => {
                chartInstance.destroy();
            };
        }
    }, [chartData]);

    return (
        <div>
            {chartData ? (
                <canvas ref={chartRef} id="nigeria-financial-chart" />
            ) : (
                <p>Loading chart...</p>
            )}
        </div>
    );
};

export default FinancialData;
