import React, { useState } from 'react';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import data from '../component/data/appointments'
import ConsultantChart from '../component/ConsultantChart';


const Consultations = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    // Calculate total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Calculate index of the first and last item of current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Slice data array to get items for the current page
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    

    return (
        <>
            <div className='flex flex-col gap-4 w-full'>
            <p className='text-xl text-[#424242] font-semibold'>
                Overview
            </p>

            <div className='w-full flex flex-row  rounded-lg p-1 gap-2 
            lg:p-5 md:gap-6'>
                <div className='flex items-center gap-1 bg-white p-2 justify-center w-40 h-20 rounded-lg 
                md:w-48'>
                    <div className='p-2 w-10 h-10 rounded-full text-white bg-[#88DF54] flex items-center justify-center'>
                        <HeadsetMicIcon />
                    </div>

                    <div className='flex flex-col gap-[1px]'>
                        <p className='font-semibold text-[#424242]'>
                        53,500
                        </p>

                        <p className='text-xs text-[#646464] text-base'>
                        Consultations
                        </p>
                    </div>
                </div>
            </div>
            </div>

            <div className='w-full mt-6 p-3 bg-white rounded-lg'>
            <div className='w-full flex items-center justify-between font-semibold mb-3'>
                <select name="people" id="" className='outline-0'>
                    <option value="">All Consultant</option>
                </select>

                <select name="time" id="" className='outline-0'>
                    <option value="">One Year</option>
                </select>
            </div>
            <ConsultantChart />
            </div>

            <div className='flex flex-col gap-4 w-full'>
            <p className='text-xl font-semibold'>
            All Transactions
            </p>

            <div className='w-full flex flex-col gap-4 bg-white rounded-lg text-xs 
            md:text-base lg:text-lg'>
                <div className='flex w-full items-center justify-between  font-semibold p-4 
                bg-[#EEF1F8] rounded-t-lg'>
                        <p className='w-1/4'>
                        Name
                    </p>

                    <p className='w-1/4'>
                    Diagnosis
                    </p>

                    <p className='w-1/4'>
                    Date and Time 
                    </p>

                    <p className='w-1/4'>
                    Status
                    </p>
                </div>

                <div className='w-full flex flex-col gap-3 p-4'>
                    {currentItems.map((item, index) => (
                        <div key={index} className='w-full flex flex-row items-start justify-between gap-4 md:gap-0 border-b border-gray-300 pb-2'>
                            <div className='w-1/4  overflow-x-auto '>
                                <p >
                                {item.Name}
                                </p>
                            </div>

                            <div className='w-1/4 overflow-x-auto md:pl-3 xl:pl-0'>
                                <p>{item.Issues}</p>
                            </div>

                            <div className='w-1/4 overflow-x-auto'>
                                <p>{item.Date}</p>
                                <p>{item.Time}</p>
                            </div>

                            <div className='w-1/4 overflow-x-auto'>
                            <p className={`w-1/4 ${
                                item.Status === 'Confirmed' ? 'text-green-500' :
                                item.Status === 'Pending' ? 'text-gray-500' :
                                item.Status === 'Cancelled' ? 'text-red-500' : ''
                            }`}>
                                {item.Status}
                            </p>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            {/* Pagination controls */}
            <div className='w-full relative'>
                <div className='flex items-center gap-5 absolute right-5'>
                    <button onClick={prevPage} disabled={currentPage === 1}
                    className='border border-[#069125] text-[#069125] font-medium px-4 py-2 rounded
                    hover:bg-[#069125] hover:text-white'>
                        Previous
                    </button>
                    <button onClick={nextPage} disabled={currentPage === totalPages}
                    className='border border-[#069125] text-[#069125] font-medium px-4 py-2 rounded
                    hover:bg-[#069125] hover:text-white'>
                        Next
                    </button>
                </div>
            </div>
            
            </div>
        </>
    );
};

export default Consultations;
