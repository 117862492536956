// getToken.js
export const saveToken = (token) => {
    try {
        localStorage.setItem('authToken', token);
        console.log("Token saved:", token);
    } catch (error) {
        console.error("Error saving token:", error);
    }
};

export const getToken = () => {
    try {
        return localStorage.getItem('authToken');
    } catch (error) {
        console.error("Error retrieving token:", error);
        return null;
    }
};
