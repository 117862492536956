const data = [
    {
      Issues: "Hypertension",
      Email: "temitope@example.com",
      Date: "05/05/2024",
      Time: "1:00 pm",
      Amount: "18,000",
    },
    {
        Issues: "Physical Wellness",
        Email: "temitope@example.com",
        Date: "07/05/2024",
        Time: "12:00 am",
        Amount: "18,000",
    },
    {
        Issues: "Allergy",
        Email: "temitope@example.com",
        Date: "01/06/2024",
        Time: "12:30 am",
        Amount: "18,000",
    },
    {
        Issues: "Asthma",
        Email: "temitope@example.com",
        Date: "11/07/2024",
        Time: "02:30 am",
        Amount: "18,000",
    },
    {
        Issues: "Asthma",
        Email: "temitope@example.com",
        Date: "11/07/2024",
        Time: "04:40 pm",
        Amount: "18,000",
    },
    {
        Issues: "Hypertension",
        Email: "temitope@example.com",
        Date: "05/05/2024",
        Time: "1:00 pm",
        Amount: "18,000",
      },
      {
          Issues: "Physical Wellness",
          Email: "temitope@example.com",
          Date: "07/05/2024",
          Time: "12:00 am",
          Amount: "18,000",
      },
      {
          Issues: "Allergy",
          Email: "temitope@example.com",
          Date: "01/06/2024",
          Time: "12:30 am",
          Amount: "18,000",
      },
      {
          Issues: "Asthma",
          Email: "temitope@example.com",
          Date: "11/07/2024",
          Time: "02:30 am",
          Amount: "18,000",
      },
      {
          Issues: "Asthma",
          Email: "temitope@example.com",
          Date: "11/07/2024",
          Time: "04:40 pm",
          Amount: "18,000",
      },
  ];
  
  export default data;
  