import React from 'react'

export default function RightPanelWrapper({rightPanelData, children}) {
  return (
    <div className='fixed inset-0 z-[999]'>
        <div className={`absolute inset-0 right_panel_transition bg-gray-400/50 dark:bg-black/50`}></div>
        <div className={`opacity absolute top-0 bottom-0 ${rightPanelData?.show ? 'right-1' : '-right-full'} w-full lg:w-[600px] bg-white text-black shadow dark:bg-slate-900 dark:text-white transition duration-1000`}>
            {children}
        </div>
    </div>
  )
}
