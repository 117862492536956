import React, { useState } from 'react';
import data from '../component/data/transactions';
import FinancialData from '../component/FinancialChartNigeria';
import FinancialChartKenya from '../component/FinancialChartKenya';
// import FinancialStats from '../component/FinancialStats';
import FinanceStats from '../component/finance/FinanceStats';

const Financials = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState('Nigeria');
    const itemsPerPage = 4;

    // Calculate total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Calculate index of the first and last item of current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Slice data array to get items for the current page
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    return (
        <>
            <div className='flex flex-col gap-4 w-full'>
                <p className='text-xl text-[#424242] font-semibold'>
                    Financials
                </p>

                <FinanceStats />
            </div>

            <div className='w-full mt-6 p-3 bg-white rounded-lg'>
                <div className='w-full flex items-center justify-between font-semibold mb-3'>
                    <p className='capitalize'>
                    revenue
                    </p>

                    <select name="time" id="" className='outline-0' onChange={handleCountryChange} value={selectedCountry}>
                        <option value="Nigeria">Naira</option>
                        <option value="Kenya">Ksh</option>
                    </select>
                </div>
                {selectedCountry === 'Nigeria' ? <FinancialData /> : <FinancialChartKenya />}
            </div>

            {/* <div className='flex flex-col gap-4 w-full'>
                <p className='text-xl font-semibold'>
                    All Transactions
                </p>

                <div className='w-full flex flex-col gap-4 bg-white rounded-lg text-xs md:text-base lg:text-lg'>
                    <div className='flex w-full items-center justify-between font-semibold p-4 bg-[#EEF1F8] rounded-t-lg'>
                        <p className='w-1/4'>
                            Email
                        </p>

                        <p className='w-1/4'>
                            Issue
                        </p>

                        <p className='w-1/4'>
                            Consultation Date and Time
                        </p>

                        <p className='w-1/4'>
                            Amount Paid
                        </p>
                    </div>

                    <div className='w-full flex flex-col gap-3 p-4'>
                        {currentItems.map((item, index) => (
                            <div key={index} className='w-full flex flex-row items-start justify-between gap-4 md:gap-0 border-b border-gray-300 pb-2'>
                                <div className='w-1/4 overflow-x-auto '>
                                    <p>
                                        {item.Email}
                                    </p>
                                </div>

                                <div className='w-1/4 overflow-x-auto md:pl-3 xl:pl-0'>
                                    <p>{item.Issues}</p>
                                </div>

                                <div className='w-1/4 overflow-x-auto'>
                                    <p>{item.Date}</p>
                                    <p>{item.Time}</p>
                                </div>

                                <div className='w-1/4 overflow-x-auto'>
                                    <p className='text-green-500'>{item.Amount}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='w-full relative'>
                    <div className='flex items-center gap-5 absolute right-5'>
                        <button onClick={prevPage} disabled={currentPage === 1}
                            className='border border-[#069125] text-[#069125] font-medium px-4 py-2 rounded hover:bg-[#069125] hover:text-white'>
                            Previous
                        </button>
                        <button onClick={nextPage} disabled={currentPage === totalPages}
                            className='border border-[#069125] text-[#069125] font-medium px-4 py-2 rounded hover:bg-[#069125] hover:text-white'>
                            Next
                        </button>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default Financials;
