import React, { useEffect, useState } from 'react';
import { getToken } from '../../utils/getToken'; // Adjust the import path as necessary
import PersonIcon from '@mui/icons-material/Person';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { NumberTo2DP } from '../../utils/NumberTo2DP';

const OverviewStat = ({period, country}) => {
    const [stats, setStats] = useState({ total_patients: 0, total_conversations: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            console.log("Retrieved token:", token);
            if (!token) {
                setError('No token found. Please login again.');
                setLoading(false);
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
            setLoading(true)
            try {
                const response = await fetch(`https://api.quickcare.ng/admin/overview/stats?start_date=${period.start_date}&end_date=${period.end_date}&country=${country}`, requestOptions);
                const result = await response.json();
                console.log("Data received from server:", result);
                if (response.ok) {
                    setStats(result);
                } else {
                    setError(result.message || 'Failed to fetch stats');
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [period, country]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="overview-stat">
            <div className='flex flex-col gap-4 w-full'>
                    <p className='text-xl text-[#424242] font-semibold'>
                        Overview
                    </p>

                    <div className='w-full grid grid-cols-2 rounded-lg gap-6 lg:w-1/2 xl:w-1/3'>
                        {/* <div className='flex items-center gap-1 '>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#308042] flex items-center justify-center'>
                                <PaymentsIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#424242] tex-lg'>
                                N 12,000,000
                                </p>

                                <p className='text-xs text-[#646464] text-base'>
                                Gross Revenue
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-1 '>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#FF6343] flex items-center justify-center'>
                                <PersonIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#424242] tex-lg'>
                                120,000,000
                                </p>

                                <p className='text-xs text-[#646464] text-base'>
                                Total Issues
                                </p>
                            </div>
                        </div> */}

                        <div className='flex items-center gap-1 bg-white p-2 justify-center w-40 h-20 rounded-lg md:w-48'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#1772B3] flex items-center justify-center'>
                                <PersonIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#424242] tex-lg'>
                                {NumberTo2DP(stats.total_patients)}
                                </p>

                                <p className='text-xs text-[#646464] text-base'>
                                Total Patients
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-1 bg-white p-2 justify-center w-40 h-20 rounded-lg md:w-48'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#88DF54] flex items-center justify-center'>
                                <HeadsetMicIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#424242] tex-lg'>
                                {NumberTo2DP(stats.total_conversations)}
                                </p>

                                <p className='text-xs text-[#646464] text-base'>
                                Consultations
                                </p>
                            </div>
                        </div>
                </div>
            </div>

           
        </div>
    );
};

export default OverviewStat;
