import React, { useState } from 'react';
import data from '../component/data/admins';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AdminList from '../component/admin/AdminList';
import AdminInvite from '../component/admin/AdminInvite';

const Admin = () => {
    const [selectedUser, setSelectedUser] = useState(null); // State to store the selected user
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;

    // Function to handle clicking on MoreVertIcon
    const handleMoreOptionsClick = (user) => {
        setSelectedUser(user);
    };

    // Function to handle enabling or disabling a user
    const handleEnableDisableUser = (action) => {
        // Here you can perform the action to enable or disable the user
        console.log(`User ${selectedUser.Name} ${action}`);
        // After performing the action, close the modal
        setSelectedUser(null);
    };

     // Calculate total number of pages
     const totalPages = Math.ceil(data.length / itemsPerPage);

     // Calculate index of the first and last item of current page
     const indexOfLastItem = currentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 
     // Slice data array to get items for the current page
     const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
 
     const nextPage = () => {
         setCurrentPage(currentPage + 1);
     };
 
     const prevPage = () => {
         setCurrentPage(currentPage - 1);
     };
 

    return (
        <>
            <>
                <div className='md:w-1/2'>
                        <AdminInvite />
                </div>
                <AdminList /> 
            </>
            {/* Modal for enabling or disabling user */}
            {selectedUser && (
                <div className="absolute inset-0 flex items-center justify-end bg-black bg-opacity-10 z-10 pr-5 xl:pr-20
                md:items-start">
                    <div className="bg-white p-3 rounded md:mt-72">
                        {/* <p className="text-xl font-semibold mb-4">Options for {selectedUser.Name}</p> */}
                        <div className="flex flex-col gap-1 font-semibold">
                            <button onClick={() => handleEnableDisableUser('enable')} className="text-green-500 flex items-center justify-center gap-2">
                                <TaskAltIcon />
                                <span>
                                Enable user
                                </span>
                            </button>
                            <button onClick={() => handleEnableDisableUser('disable')} className="text-red-500 flex items-center justify-center gap-2">
                            <CancelOutlinedIcon />
                                <span>
                                Disable user
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Admin;
