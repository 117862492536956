
export default function SectionWrapper({ bgColor, className, children }) {
  return (
    <div className={`${bgColor || ""} ${className || ""}`}>
      <div className="p-3 md:p-6 m-auto w-full h-full">
        {/* {typeof children === 'function' ? children() : children} */}
        {children}
      </div>
    </div>
  )
}


// NOTE
/* Using classname to add things like 
padding to section wrapper container
*/