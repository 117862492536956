import React, { useState } from 'react';
import { getToken } from '../utils/getToken'; // Adjust the import path as necessary

const EditProfileModal = ({ isOpen, onClose, onSave }) => {
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleSave = async () => {
        const token = getToken();
        console.log("Retrieved token:", token);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            full_name: fullName,
            phone_number: phoneNumber
        });

        console.log("Data sent to server:", raw);

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://api.quickcare.ng/admin/profile", requestOptions);
            const result = await response.json();
            console.log("Response received from server:", result);

            if (response.ok) {
                onSave(result.data); // Assuming the response contains updated profile data
            } else {
                console.error("Failed to update profile:", result.message);
            }
        } catch (error) {
            console.error("Fetch error:", error);
        } finally {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className='fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center w-full'>
            <div className='bg-white p-6 rounded-md w-3/4 md:w-1/2 md:ml-20'>
                <h2 className='text-lg font-medium mb-4'>Edit Profile</h2>
                <div className='mb-4'>
                    <label className='block mb-2'>Full Name</label>
                    <input
                        type='text'
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        className='w-full p-2 border rounded-md'
                    />
                </div>
                <div className='mb-4'>
                    <label className='block mb-2'>Phone Number</label>
                    <input
                        type='text'
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className='w-full p-2 border rounded-md'
                    />
                </div>
                <div className='flex justify-end gap-4'>
                    <button onClick={onClose} className='px-4 py-2 bg-gray-300 rounded-md'>Cancel</button>
                    <button onClick={handleSave} className='px-4 py-2 bg-green-600 text-white rounded-md'>Save</button>
                </div>
            </div>
        </div>
    );
};

export default EditProfileModal;
