import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BadgeIcon from '@mui/icons-material/Badge';
import PaymentsIcon from '@mui/icons-material/Payments';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const Sidebar = () => {
    const location = useLocation();

    const navigate = useNavigate()

    const logout = () => {
        navigate('/', {replace:true})
        localStorage.clear()
    }

    return (
        <div className='w-[300px] flex flex-col h-screen overflow-y-auto justify-between items-start p-3 py-6 shadow-2xl pb-28 fixed bg-white z-50'>
            <div className='flex flex-col gap-6 w-full '>
                <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                 ${location.pathname === '/overview' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} to="/overview">
                    <BadgeIcon />
                    <span>
                        Overview
                    </span>
                </Link>

                <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                 ${location.pathname === '/financials' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} to="/financials">
                    <PaymentsIcon />
                    <span>
                    Financials
                    </span>
                </Link>

                {/* <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                ${location.pathname === '/consultations' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} to="/consultations">
                    <CalendarMonthIcon />
                    <span>
                        Consultations
                    </span>
                </Link> */}

                <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                ${location.pathname === '/patients' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} to="/patients">
                    <VaccinesIcon />
                    <span>
                    Patients
                    </span>
                </Link>

                <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                ${location.pathname === '/admin' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} to="/admin">
                    <ManageAccountsIcon />
                    <span>
                    Admins
                    </span>
                </Link>
            </div>

            <div className='flex flex-col gap-6 w-full'>
                <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                ${location.pathname === '/settings' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} to="/settings">
                    <SettingsIcon />
                    <span>
                        Settings
                    </span>
                </Link>

                <button onClick={logout} className={`flex gap-1 items-center w-full p-2 rounded-lg text-[#E12C2C] hover:font-semibold 
                ${location.pathname === '/logout' ? 'bg-[#CDE9D3]  font-semibold' : ''}`} to="/">
                    <LogoutIcon />
                    <span>
                        Logout
                    </span>
                </button>
            </div>
        </div>
    )
}

export default Sidebar;
