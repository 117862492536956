import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import Layout from "../Layout"
import { updateUserDetails } from "../../store/UserDetails"

import PageIsLoading from "../../component/loaders/PageIsLoading"
import { getToken } from "../../utils/getToken"


export default function UserExist() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [pageIsLoading, setPageIsLoading] = useState(true)

    const {userDetails} = useSelector((state) => state.userDetails)

    const token = getToken(); // Get the token using the getToken function

    useEffect(() => {
        if (!token) {
            return navigate('/', {replace:true})
        }

        if(Object.keys(userDetails) < 1){
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`); // Use the token from local storage
    
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
    
            fetch("https://api.quickcare.ng/admin/profile", requestOptions)
                .then((response) => response.json()) // Changed to .json() for better handling of JSON data
                .then((result) => {
                    console.log('RESULT USER EXIST', result); // Log the result
                    if (result.success) {
                        dispatch(updateUserDetails(result.data))
                        setPageIsLoading(false)
                    } else {
                        setPageIsLoading(false)
                        navigate('/', {replace:true})
                    }
                })
                .catch((error) => {
                    setPageIsLoading(false)
                    navigate('/', {replace:true})
                });
        }else{
            setPageIsLoading(false)
        }
    }, []);

  return (
    <>
    {pageIsLoading ?
    <PageIsLoading />
    :
    <Layout></Layout>
    }
    </>
  )
}
