const data = [
    {
      Issues: "Hypertension",
      Name: "Temitope Akande",
      Date: "05/05/2024",
      Time: "1:00 pm",
      Status: "Confirmed",
    },
    {
        Issues: "Physical Wellness",
        Name: "Aisha Yusuf",
        Date: "07/05/2024",
        Time: "12:00 am",
        Status: "Pending",
    },
    {
        Issues: "Allergy",
        Name: "David Williams ",
        Date: "01/06/2024",
        Time: "12:30 am",
        Status: "Confirmed",
    },
    {
        Issues: "Asthma",
        Name: "Bolalji Haruna",
        Date: "11/07/2024",
        Time: "02:30 am",
        Status: "Cancelled",
    },
    {
        Issues: "Asthma",
        Name: "Anna Balogun",
        Date: "11/07/2024",
        Time: "04:40 pm",
        Status: "Confirmed",
    },
    {
        Issues: "Hypertension",
        Name: "Joan Akintola",
        Date: "05/05/2024",
        Time: "1:00 pm",
        Status: "Confirmed",
       
      },
      {
          Issues: "Physical Wellness",
          Name: "Naomi Wells",
          Date: "07/05/2024",
          Time: "12:00 am",
          Status: "Confirmed",
      },
      {
          Issues: "Allergy",
          Name: "Temitope Akande",
          Date: "01/06/2024",
          Time: "12:30 am",
          Status: "Pending",
      },
      {
          Issues: "Asthma",
          Name: "Aisha Yusuf",
          Date: "11/07/2024",
          Time: "02:30 am",
          Status: "Pending",
      },
      {
          Issues: "Asthma",
          Name: "David Williams",
          Date: "11/07/2024",
          Time: "04:40 pm",
          Status: "Cancelled",
      },
  ];
  
  export default data;
  