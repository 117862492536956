import React, { useState } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import PersonalInfo from '../component/PersonalInfo';
import ChangePassword from '../component/ChangePassword';
import EditProfileModal from '../component/EditProfileModal';

const Settings = () => {
    const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
    const [isSubscriptionVisible, setIsSubscriptionVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const togglePersonalInfo = () => {
        setIsPersonalInfoVisible(true);
        setIsSubscriptionVisible(false);
    };

    const toggleSubscription = () => {
        setIsPersonalInfoVisible(false);
        setIsSubscriptionVisible(true);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveProfile = (data) => {
        console.log('Profile data:', data);
        // You can handle saving the profile data here
    };

    return (
        <>
            <>
            <div className='w-full flex items-center justify-between '>
                <div className='flex gap-3 text-sm items-center md:text-base lg:gap-6'>
                    <button onClick={togglePersonalInfo} className={isPersonalInfoVisible ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                        Personal Info
                    </button>
                    <button onClick={toggleSubscription} className={isSubscriptionVisible ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                        Change Password 
                    </button>
                </div>

                <div>
                    {isPersonalInfoVisible && (
                        <button
                            onClick={handleOpenModal}
                            className='flex gap-1 bg-[#069125] text-white font-medium py-1 px-2 items-center rounded-md text-sm md:text-base md:py-2 md:px-4'>
                            <CreateIcon />
                            <span>Edit Profile</span>
                        </button>
                    )}
                </div>
            </div>

            {isPersonalInfoVisible && (
                <div>
                    <PersonalInfo />
                </div>
            )}

            {isSubscriptionVisible && (
                <div>
                    <ChangePassword />
                </div>
            )}
            </>
            <EditProfileModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveProfile} />
        </>

    );
};

export default Settings;
