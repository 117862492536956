// FUNCTION TO RETURN AMOUNT TO TWO DECIMAL PLACES
export const NumberTo2DP = (
    amount = "00",
  ) => {
    // Convert the number to a string
    let numStr = String(amount);
  
    // Split the string into integer and decimal parts
    let parts = numStr.split(".");
    let integerPart = parts[0] || "";
    let decimalPart = parts[1] || "";
  
    // Add thousands separators to the integer part
    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Truncate or pad the decimal part to two decimal points
    let formattedDecimal = decimalPart.slice(0, 2).padEnd(2, "0");
  
    // Combine the formatted integer and decimal parts
    let formattedNumber = formattedInteger + '.' + formattedDecimal;
  
    // return formattedNumber;
    return formattedNumber;
  };