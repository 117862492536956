const data = [
    {
      Status: "Active",
      Email: "admin@quickcare.com",
      Date: "05/05/2024",
      Amount: "80,000.00",
      Name: "Roya Aledei",
      PhoneNo: "08178230133",
    },
    {
        Status: "Disabled",
        Email: "admin@quickcare.com",
        Date: "07/05/2024",
        Amount: "18,000.00",
        Name: "Roya Aledei",
        PhoneNo: "08178230133",
    },
    {
        Status: "Active",
        Email: "admin@quickcare.com",
        Date: "01/06/2024",
        Amount: "32,000.00",
        Name: "Roya Aledei",
        PhoneNo: "08178230133",

    },      
  ];
  
  export default data;
  