import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stereo from '../images/stetoscope.png';
import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import { saveToken } from '../utils/getToken'; // Adjust the import path as necessary

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");

        const urlencoded = new URLSearchParams();
        urlencoded.append("username", username);
        urlencoded.append("password", password);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        console.log("Data sent:", { username, password });

        try {
            const response = await fetch("https://api.quickcare.ng/admin/auth/login", requestOptions);
            const result = await response.json();
            console.log("Response received:", result);

            if (response.ok) {
                const token = result.access_token; // Adjust this according to the actual response structure
                if (token) {
                    saveToken(token);
                    console.log("Access Token:", token);
                    navigate('/overview');
                } else {
                    console.error("Token not found in response:", result);
                }
            } else {
                console.error("Login failed:", result.message);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className='w-full md:flex flex-row h-screen'>
            <div className='w-full flex flex-col gap-4 p-7 md:w-1/2 md:items-start md:justify-center md:gap-16 lg:gap-8'>
                <Link to='/'>
                    <img src={Logo} alt="" className='w-[150px] h-[30px]' />
                </Link>

                <div className='mt-4 flex w-full flex-col gap-2'>
                    <h1 className='text-2xl font-semibold'>
                        Admin Login
                    </h1>
                </div>

                <form onSubmit={handleSubmit} className='flex flex-col w-full gap-4 items-center md:gap-8'>
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="text"
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='User Name / ID number'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="password"
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className='flex items-center w-full justify-between mt-2'>
                        <div className='flex items-center gap-2'>
                            <input type="checkbox" className='w-5 h-5' />
                            <p className='text-sm'>
                                Remember me
                            </p>
                        </div>

                        <Link className='text-sm'>
                            Forgot Password
                        </Link>
                    </div>

                    <button
                        type='submit'
                        className='w-full flex items-center justify-center p-2 rounded bg-[#069125] font-medium text-white tracking-wider'>
                        Login
                    </button>
                </form>
            </div>

            <img src={Stereo} alt="" className='hidden md:flex w-1/2' />
        </div>
    );
};

export default Login;
