import React, { useState, useEffect } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import data from '../component/data/patients-information';
// import PatientCharts from '../component/PatientChart';
import { getToken } from '../utils/getToken'; // Import the getToken function
// import OverviewRecentConversations from '../component/overview/OverviewRecentConversations';
import AllPatient from '../component/patient/AllPatient';
import { NumberTo2DP } from '../utils/NumberTo2DP';

const Patients = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPatients, setTotalPatients] = useState(0);
    const [signupsThisMonth, setSignupsThisMonth] = useState(0);
    const itemsPerPage = 4;

    // Calculate total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Calculate index of the first and last item of current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Slice data array to get items for the current page
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    useEffect(() => {
        const fetchStats = async () => {
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const token = getToken(); // Retrieve the token from localStorage
            if (token) {
                console.log('Retrieved token:', token);
                myHeaders.append("Authorization", `Bearer ${token}`);
            } else {
                console.error('No token found');
                return;
            }

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            try {
                const response = await fetch("https://api.quickcare.ng/admin/patients/stats", requestOptions);
                const result = await response.json();
                console.log('Server response:', result);

                // Extracting data from the result
                const { signups_this_month, total_customers } = result.data;

                setTotalPatients(total_customers);
                setSignupsThisMonth(signups_this_month);
            } catch (error) {
                console.error('Error fetching patient stats:', error);
            }
        };

        fetchStats();
    }, []);

    return (
        <>
            <div className='flex flex-col gap-4 w-full'>
                <p className='text-xl text-[#424242] font-semibold'>
                    Patients
                </p>

                <div className='w-full flex flex-row rounded-lg p-1 gap-2 md:gap-6'>
                    <div className='flex items-center gap-1 bg-white p-2 justify-center w-40 h-20 rounded-lg md:w-48'>
                        <div className='p-2 w-10 h-10 rounded-full text-white bg-[#1772B3] flex items-center justify-center'>
                            <PersonIcon />
                        </div>

                        <div className='flex flex-col gap-[1px]'>
                            <p className='font-semibold text-[#424242] tex-lg'>
                                {NumberTo2DP(totalPatients)}
                            </p>

                            <p className='text-xs text-[#646464] text-base'>
                                Total Patients
                            </p>
                        </div>
                    </div>

                    <div className='flex  items-center gap-1 bg-white p-2 justify-center w-40 h-20 rounded-lg md:w-48'>
                        <div className='p-2 w-10 h-10 rounded-full text-white bg-[#FF6343] flex items-center justify-center'>
                            <PersonIcon />
                        </div>

                        <div className='flex flex-col gap-[1px]'>
                            <p className='font-semibold text-[#424242] tex-lg'>
                                {NumberTo2DP(signupsThisMonth)}
                            </p>

                            <p className='text-xs text-[#646464] text-base'>
                                Sign-Up this month
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='w-full mt-6 p-3 bg-white rounded-lg'>
                <div className='w-full flex items-center justify-between font-semibold mb-3'>
                    <ul className='flex items-center gap-4 '>
                        <li className='list-disc ml-6 text-[#1772B3]'>Patients</li>
                        <li className='list-disc ml-6 text-[#FF6343]'>Issues</li>
                    </ul>
                </div>
                <PatientCharts />
            </div> */}

            <div className='flex flex-col gap-4 w-full'>
                <p className='text-xl font-semibold'>
                    All Patients
                </p>

                <AllPatient />
            </div>
        </>
    );
};

export default Patients;
