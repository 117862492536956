import React from 'react'
import RightPanelWrapper from '../../component/wrappers/RightPanelWrapper'

export default function RightPanel({rightPanelData, onPanelClose}) {

    const {details} = rightPanelData

  return (
    <RightPanelWrapper rightPanelData={rightPanelData}>
        <div className="h-full flex flex-col relative overflow-y-auto">
            {/* <!-- Modal header --> */}
            <div className="h-[85px] flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <div className=''>
                    <h3 className="text-xl font-semibold text-black dark:text-white">
                        Response
                    </h3>
                </div>
                <button onClick={onPanelClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-4 h-full flex flex-col gap-4">
                <div className='w-full'>
                    <div className='flex items-center gap-2 px-4 py-4 border-b border-gray-200'>
                        <div className='flex flex-col gap-1'>
                            <span className='font-semibold text-xl'>Date</span>
                            <span className='text-10'>{new Date(details.created_at).toLocaleString()}</span>
                        </div>
                    </div>
                </div>

                <div className='h-full flex flex-col gap-4 justify-between'>
                    <div className='flex flex-col gap-4'>
                        <div className='p-4 border rounded-xl flex flex-col gap-4'>
                            <div className='border-t border-gray-200'>
                                <label className='font-semibold text-xl'>Response</label>
                                <div className='flex gap-1 items-baseline'>
                                    <span className='text-12 text-gray-700 tracking-wide leading-8'>{details.response}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full py-2 dark:border-gray-600">
                        {/* BTN */}
                    </div>
                </div>  
            </div>
        </div>
    </RightPanelWrapper>
  )
}
