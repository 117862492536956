import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement
);

const ConsultantChart = () => {
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",],
        datasets: [{
            data: [30,42, 65, 50, 58, 18, 67, 41, 55,38, 60,20],
            backgroundColor: 'transparent',
            borderColor: '#88DF54',
            pointBorderColor: 'transparent',
            tension: 0,
        }],
    };

    const options = {
        plugins: {
            legend: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    borderDash: [10]
                }
            },
            y: {
                min: 0,
                max: 80,
                ticks: {
                    stepSize: 20,
                    callback: (value) => value + '',
                },
                grid: {
                    borderDash: [15]
                }
            }
        },
    };// Define your options here

    return (
        <div className=''>
           <Line data={data} options={options}></Line>
        </div>
    );
};

export default ConsultantChart;

