const data = [
    {
      Issues: "Hypertension",
      Email: "patient@example.com",
      Date: "05/05/2024",
      Amount: "80,000.00",
    },
    {
        Issues: "Physical Wellness",
        Email: "patient@example.com",
        Date: "07/05/2024",
        Amount: "18,000.00",
    },
    {
        Issues: "Allergy",
        Email: "patient@example.com",
        Date: "01/06/2024",
        Amount: "32,000.00",
    },
    {
        Issues: "Asthma",
        Email: "patient@example.com",
        Date: "11/07/2024",
        Amount: "18,000.00",
    },
    {
        Issues: "Asthma",
        Email: "patient@example.com",
        Date: "11/07/2024",
        Amount: "145,000.00",
    },
    {
        Issues: "Hypertension",
        Email: "patient@example.com",
        Date: "05/05/2024",
        Amount: "282,300.00",
      },
      {
          Issues: "Physical Wellness",
          Email: "patient@example.com",
          Date: "07/05/2024",
          Amount: "18,000.00",
      },
      {
          Issues: "Allergy",
          Email: "patient@example.com",
          Date: "01/06/2024",
          Amount: "932,080.00",
      },
      
  ];
  
  export default data;
  