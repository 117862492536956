import React, { useState, useEffect } from 'react';
import Profile from "../images/profile.png";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { getToken } from '../utils/getToken'; // Adjust the import path as necessary

const PersonalInfo = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [profile, setProfile] = useState({
        full_name: '',
        dateOfBirth: '',
        gender: '',
        homeAddress: '',
        profilePhoto: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
            const imageUrl = URL.createObjectURL(imageFile);
            setSelectedImage(imageUrl);
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            const token = getToken();
            console.log("Retrieved token:", token);

            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            try {
                const response = await fetch("https://api.quickcare.ng/admin/profile", requestOptions);
                const result = await response.json();
                console.log("Response received from server:", result);

                if (response.ok) {
                    const data = result.data;
                    setProfile({
                        full_name: data.full_name || 'N/A',
                        dateOfBirth: data.dateOfBirth || 'N/A',
                        gender: data.gender || 'N/A',
                        homeAddress: data.homeAddress || 'N/A',
                        profilePhoto: data.photo || Profile,
                        role: data.role || 'N/A',
                        email: data.email || 'N/A',
                        phone_number: data.phone_number || 'N/A',
                        active: data.active ? 'Active' : 'Inactive'
                    });
                } else {
                    setError(result.message || 'Failed to fetch profile data');
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setError('An error occurred');
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className='w-full h-fit flex flex-col gap-4 border border-gray-300 rounded mt-4 bg-white mb-96'>
            <div className='w-full border-b border-gray-300'>
                <div className='p-2 lg:p-6 flex w-full items-center justify-between text-sm gap-3
                md:w-2/3 md:text-base lg:w-3/5 xl:w-2/5'>
                    <div className='flex flex-col gap-1'>
                        <p className='font-semibold'>
                            Profile Photo
                        </p>
                        <p className='text-gray-500'>
                            This image will be displayed on your profile
                        </p>
                        <label htmlFor="image-upload" className="cursor-pointer border border-[#069125] w-2/3 text-[#069125] font-medium py-2 
                        flex items-center gap-1 px-1 rounded text-sm justify-center">
                            <ImageOutlinedIcon />
                            <span>
                                Change Photo
                            </span>
                        </label>
                        <input id="image-upload" type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
                    </div>
                    <img src={selectedImage || profile.profilePhoto} alt="Profile" className='w-16 h-16 rounded-full' />
                </div>
            </div>

            <div className='px-2 lg:px-6 pt-1 pb-6 lg:pb-10 flex flex-col gap-3 w-full text-sm md:text-base'>
                <p className='font-semibold'>
                    Personal Information
                </p>

                <div className=''>
                    <p className='text-gray-400'>
                        Name
                    </p>
                    <p>
                        {profile.full_name}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                        Role
                    </p>
                    <p>
                        {profile.role}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                        Email
                    </p>
                    <p>
                        {profile.email}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                        Phone No
                    </p>
                    <p>
                        {profile.phone_number}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                        Status
                    </p>
                    <p>
                        {profile.active}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo;
