import React, { useState } from 'react';
import { getToken } from '../../utils/getToken'; // Adjust the import path as necessary

const AdminInvite = () => {
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('Admin');
    const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleInvite = async () => {
        const token = getToken();
        console.log("Retrieved token:", token);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            email,
            role
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://api.quickcare.ng/admin/admins/invite-admin", requestOptions);
            const result = await response.json();
            console.log("Data sent to server:", raw);
            console.log("Response received from server:", result);

            if (response.ok) {
                setResponseMessage('User invited successfully');
                setErrorMessage('');
            } else {
                setErrorMessage(result.message || 'Failed to invite user');
                setResponseMessage('');
            }
        } catch (error) {
            console.error("Fetch error:", error);
            setErrorMessage('An error occurred');
            setResponseMessage('');
        }
    };

    return (
        <div className="w-full flex flex-col gap-3">
            <h1 className='text-xl text-[#424242] font-semibold'>Invite Admin</h1>
            <div className="flex flex-col gap-2">
                <input
                    type="email"
                    placeholder="User Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-4 py-2 border border-gray-300 rounded outline-0"
                />
                <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="px-4 py-2 border border-gray-300 rounded outline-0"
                >
                    <option value="Admin">Admin</option>
                    <option value="Super Admin">Super Admin</option>
                </select>
                <button
                    onClick={handleInvite}
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Invite
                </button>
                {responseMessage && <p className="text-green-500">{responseMessage}</p>}
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default AdminInvite;
