import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import { getToken } from '../utils/getToken';

const FinancialChartKenya = () => {
    const [chartData, setChartData] = useState(null);
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchFinancialData = async () => {
            const token = getToken();
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            try {
                const response = await fetch("https://api.quickcare.ng/admin/financials/monthly-subscriptions?country=kenya", requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log('Server response:', result); // Log the server response

                // Process the data
                const { data } = result;
                const labels = Object.keys(data);
                const values = Object.values(data);

                // Set chart data
                const newChartData = {
                    labels: labels,
                    datasets: [{
                        label: 'Monthly Revenue',
                        data: values,
                        backgroundColor: '#308042',
                        borderColor: '#1c6d40',
                        borderWidth: 1
                    }],
                };

                setChartData(newChartData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchFinancialData();
    }, []);

    useEffect(() => {
        if (chartRef.current && chartData) {
            const chartInstance = new Chart(chartRef.current, {
                type: 'bar',
                data: chartData,
                options: {
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    return `${tooltipItem.label}: ${tooltipItem.raw}`;
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                display: true,
                                borderDash: [10]
                            },
                            ticks: {
                                autoSkip: false, // Ensure all labels are displayed
                            }
                        },
                        y: {
                            min: 0,
                            max: 2000,
                            ticks: {
                                stepSize: 100,
                                callback: function(value) {
                                    return value.toLocaleString(); // Format numbers with commas
                                }
                            },
                            grid: {
                                borderDash: [15]
                            }
                        }
                    }
                }
            });

            return () => {
                chartInstance.destroy();
            };
        }
    }, [chartData]);

    return (
        <div>
            {chartData ? (
                <canvas ref={chartRef} id="kenya-financial-chart" />
            ) : (
                <p>Loading chart...</p>
            )}
        </div>
    );
};

export default FinancialChartKenya;
