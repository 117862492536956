import React, { useEffect, useState } from "react";
import { getToken } from "../../utils/getToken"; // Adjust the import path based on your file structure

const AllPatient = () => {
    const [patients, setPatients] = useState([]);
    const [page, setPage] = useState(1); // State for managing the current page
    const [totalPatients, setTotalPatients] = useState(0); // State for storing the total number of patients
    const pageSize = 10; // Define the number of patients per page

    const fetchPatients = async (pageNumber) => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        const token = getToken(); // Get the token from local storage using the getToken function
        if (token) {
            myHeaders.append("Authorization", `Bearer ${token}`);
        } else {
            console.error("Token not found");
            return;
        }

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `https://api.quickcare.ng/admin/patients/?page=${pageNumber}&page_size=${pageSize}&search_term=`,
                requestOptions
            );
            const result = await response.json();
            if (result.success) {
                setPatients(result.data);
                setTotalPatients(result.total); // Assuming the API returns a `total` field indicating the total number of patients
            } else {
                console.error("Failed to fetch patients data");
            }
        } catch (error) {
            console.error("Error fetching patients:", error);
        }
    };

    useEffect(() => {
        fetchPatients(page); // Fetch patients whenever the page changes
    }, [page]);

    const handleNextPage = () => {
        if (page < Math.ceil(totalPatients / pageSize)) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <div className="p-6 bg-gray-100 capitalize">
            <h2 className="text-xl font-semibold mb-4 text-gray-700">Patient Table</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">ID</th>
                            <th className="py-3 px-6 text-left">Full Name</th>
                            <th className="py-3 px-6 text-left">Subscribed</th>
                            <th className="py-3 px-6 text-left">Email</th>
                            <th className="py-3 px-6 text-left">Phone Number</th>
                            <th className="py-3 px-6 text-left">Country</th>
                            <th className="py-3 px-6 text-left">Registration Date</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm font-light">
                        {patients.map((patient) => (
                            <tr
                                key={patient.id}
                                className="border-b border-gray-200 hover:bg-gray-100 "
                            >
                                <td className="py-3 px-6 text-left">{patient.id}</td>
                                <td className="py-3 px-6 text-left">{patient.first_name} {patient.last_name}</td>
                                <td className="py-3 px-6 text-left">
                                    {patient.is_subscribed ? "Yes" : "No"}
                                </td>
                                <td className="py-3 px-6 text-left">{patient.email}</td>
                                <td className="py-3 px-6 text-left">{patient.phone_number}</td>
                                <td className="py-3 px-6 text-left">{patient.country}</td>
                                <td className="py-3 px-6 text-left">
                                    {new Date(patient.registration_date).toLocaleDateString()}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between mt-4">
                <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                    onClick={handlePreviousPage}
                    disabled={page === 1}
                >
                    Previous
                </button>
                <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                    onClick={handleNextPage}
                    disabled={page >= Math.ceil(totalPatients / pageSize)} // Disable if it's the last page
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default AllPatient;
