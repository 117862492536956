import React, { useEffect, useState } from 'react';
import { getToken } from '../../utils/getToken'; // Adjust the import path as necessary
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';

const AdminList = () => {
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null); // State to store retrieved token
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchAdmins = async () => {
            const retrievedToken = getToken();
            setToken(retrievedToken);

            if (!retrievedToken) {
                setError('No token found. Please login again.');
                setLoading(false);
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${retrievedToken}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            try {
                const response = await fetch("https://api.quickcare.ng/admin/admins", requestOptions);
                const result = await response.json();

                if (response.ok) {
                    setAdmins(result.data); // Adjust this according to the actual response structure
                } else {
                    setError(result.message || 'Failed to fetch admins');
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAdmins();
    }, []);

    const handleModalOpen = (admin) => {
        setSelectedAdmin(admin);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedAdmin(null);
    };

    const handleDeleteAdmin = async (admin) => {
        const adminId = admin.id; // Assuming admin has an id field
        const adminIndex = admins.findIndex(a => a.id === adminId);

        if (adminIndex === -1) {
            console.error(`Admin with id ${adminId} not found.`);
            return;
        }

        const adminName = admins[adminIndex].full_name; // Adjust according to your data structure

        if (window.confirm(`Are you sure you want to delete admin ${adminName}?`)) {
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow"
            };

            try {
                const response = await fetch(`https://api.quickcare.ng/admin/admins/${adminId}`, requestOptions);

                if (response.ok) {
                    // Remove the deleted admin from the state
                    setAdmins(prevAdmins => prevAdmins.filter(a => a.id !== adminId));
                    alert(`Admin ${adminName} has been successfully deleted.`);
                } else {
                    const result = await response.json();
                    setError(result.message || 'Failed to delete admin');
                }
            } catch (error) {
                console.error("Delete admin error:", error);
                setError(error.message);
            } finally {
                setIsModalOpen(false);
                setSelectedAdmin(null);
            }
        }
    };

    const handleChangeAdminRole = async (admin) => {
        // Your implementation for changing admin role
        console.log("Changing admin role:", admin);
        // You can implement the role change functionality here
        alert(`Changing role for ${admin.full_name}`);
        setIsModalOpen(false);
        setSelectedAdmin(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="admin-list">
            <h1>List of Admins</h1>

            <div className="table-container overflow-x-auto">
                <table className="table-auto w-full border-collapse border border-gray-200">
                    <thead>
                        <tr>
                            <th className="border border-gray-200 px-4 py-2">Name</th>
                            <th className="border border-gray-200 px-4 py-2">Email</th>
                            <th className="border border-gray-200 px-4 py-2">Role</th>
                            <th className="border border-gray-200 px-4 py-2">Phone No</th>
                            <th className="border border-gray-200 px-4 py-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {admins.map((admin, index) => (
                            <tr key={index}>
                                <td className="border border-gray-200 px-4 py-2">{admin.full_name}</td>
                                <td className="border border-gray-200 px-4 py-2">{admin.email}</td>
                                <td className="border border-gray-200 px-4 py-2">{admin.role}</td>
                                <td className="border border-gray-200 px-4 py-2">{admin.phone_number}</td>
                                <td className="border border-gray-200 px-4 py-2">
                                    <button onClick={() => handleModalOpen(admin)}>
                                        <MoreVertIcon />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isModalOpen && selectedAdmin && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="absolute inset-0 bg-gray-600 opacity-75" onClick={handleModalClose}></div>
                    <div className="bg-white p-4 rounded shadow-lg z-10 flex flex-col gap-4 items-end">
                        {/* <button className="mt-4 px-4 py-2" onClick={handleModalClose}>
                            <CloseIcon />
                        </button> */}

                        <div>
                            <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={() => handleDeleteAdmin(selectedAdmin)}>Delete admin</button>
                            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => handleChangeAdminRole(selectedAdmin)}>Change admin role</button>
                        </div>
                        
                        
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminList;
