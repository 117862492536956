import './App.css';
// import Settings from './pages/Settings';
import Financials from './pages/Financials';
import Overview from './pages/Overview';
import Consultations from './pages/Consultations';
import Admin from './pages/Admin';
import Patients from './pages/Patients';
import Login from './pages/Login';
import Settings from './pages/Settings';
import { Routes, Route } from 'react-router-dom';
import UserExist from './component/authorization/UserExist';


function App() { 
  return (
    <Routes>
      <Route element={<UserExist />}>
        {/* <Route path="/settings" element={<Settings />} /> */}
        <Route path="/overview" element={<Overview />} />
        <Route path="/financials" element={<Financials />} />
        <Route path="/consultations" element={<Consultations />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/patients" element={<Patients />} />
        <Route path="/chart" element={<Patients />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="/" element={<Login />} />
    </Routes>
  );
}

export default App;