import React, { useEffect, useState } from 'react';
import { getToken } from '../../utils/getToken'; // Adjust the import path as necessary
import RightPanel from './RightPanel';

const OverviewRecentConversations = () => {
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const [rightPanelData, setRightPanelData] = useState({
        details: {},
        show: false,
    })

    const onPanelOpen = (details) => {
        setRightPanelData({...details, show:true})
    }

    const onPanelClose = () => {
        setRightPanelData(prev => ({...prev, show:false}))
    }

    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            console.log("Retrieved token:", token);
            if (!token) {
                setError('No token found. Please login again.');
                setLoading(false);
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            try {
                const response = await fetch("https://api.quickcare.ng/admin/overview/recent-conversations", requestOptions);
                const result = await response.json();
                console.log("Data received from server:", result);

                if (response.ok) {
                    setConversations(result.data); // Adjust this according to the actual response structure
                } else {
                    setError(result.message || 'Failed to fetch recent conversations');
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = conversations.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(conversations.length / itemsPerPage);

    const getPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pageNumbers.push(1, 2, 3, 4, '...', totalPages);
            } else if (currentPage > totalPages - 2) {
                pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            } else {
                pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }
        return pageNumbers;
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div className="w-full flex flex-col gap-3">
                <h1 className='text-xl text-[#424242] font-semibold'>Recent Conversations</h1>
                <div className="table-container overflow-x-auto">
                    <table className="table-auto border-collapse border border-gray-200">
                        <thead className='w-full'>
                            <tr className='w-full'>
                                <th className="border border-gray-200 px-4 py-2">User ID</th>
                                <th className="border border-gray-200 px-4 py-2">Response</th>
                                <th className="border border-gray-200 px-4 py-2">Message</th>
                                <th className="border border-gray-200 px-4 py-2">Date and Time</th>
                            </tr>
                        </thead>
                        <tbody className='w-full'>
                            {currentItems.map((conversation, index) => (
                                <tr key={index} className='w-full'>
                                    <td className="border border-gray-200 px-4 py-2">{conversation.user_id}</td>
                                    <td className="border border-gray-200 px-4 py-2">
                                        {conversation.response.substr(0,200)}
                                        <span>...</span>
                                        <span className='cursor-pointer rounded-2xl px-2 py-1 bg-sky-200 text-black' onClick={()=>onPanelOpen({details:{...conversation}})}>More</span>
                                    </td>
                                    <td className="border border-gray-200 px-4 py-2">{conversation.message}</td>
                                    <td className="border border-gray-200 px-4 py-2">{new Date(conversation.created_at).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="pagination mt-4 flex justify-between w-full gap-2 lg:flex-row lg:w-full lg:justify-center">
                    <button
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-3 py-1 mx-1 border border-gray-300 rounded disabled:opacity-50"
                    >
                        Previous
                    </button>
                    {getPageNumbers().map((number, index) => (
                        <button
                            key={index}
                            onClick={() => typeof number === 'number' && paginate(number)}
                            className={`px-3 py-1 mx-1 border border-gray-300 rounded hidden lg:flex ${number === currentPage ? 'bg-gray-200' : ''}`}
                        >
                            {number}
                        </button>
                    ))}
                    <button
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="px-3 py-1 mx-1 border border-gray-300 rounded disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>
            </div>

            {rightPanelData.show &&
                <RightPanel rightPanelData={rightPanelData} onPanelClose={onPanelClose} />
            }
        </>
    );
};

export default OverviewRecentConversations;
