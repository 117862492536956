import React, { useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom';

export default function Layout({children}) {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

  return (
    <div className='w-full bg-[#F9FAFB]'>
        <div className='z-[999] fixed w-full'>
            <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
        </div>
        
        <div className={`pt-16 md:pt-20 w-full`}>
            <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden md:block z-50'}`}>
                <Sidebar />
            </div>

            <div className='pb-96 md:ml-[300px] px-3 flex flex-col gap-7 mt-4'>
                {children || <Outlet />}
            </div>
        </div>
    </div>
  )
}
